<template>
  <vxe-table border stripe max-height="300px" :data="productList">
    <vxe-column field="productCode" title="产品编码" show-overflow></vxe-column>
    <vxe-column field="productName" title="产品名称" show-overflow></vxe-column>
    <vxe-column field="productNum" title="产品数量" show-overflow></vxe-column>
  </vxe-table>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value() {
      this.productList = this.value;
    },
  },
  data() {
    return {
      productList: [],
    };
  },
};
</script>
