<template>
  <div class="img-wrap">
    <el-image
      v-for="(item, index) in imgList"
      :key="index"
      style="width: 100px; height: 100px;margin-right:20px"
      :src="item"
      :preview-src-list="imgList"
    >
    </el-image>
      <video
      v-for="(item) in videoList"
      :key="item.id"
      style="width: 200px; height: 100px;margin-right:20px"
      :src="item"
      :controls="controls"
    >
    </video>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value(newVal, oldVal) {
      const tempArr = [];
      const videoArr = [];
      const photoVoList = newVal.filter((f) => f.fileUrl.indexOf('mp4') === -1);
      const videoVoList = newVal.filter((f) => f.fileUrl.indexOf('mp4') !== -1);
      // for (const item of newVal) {
      //   tempArr.push(item.fileUrl);
      // }
      for (const item of photoVoList) {
        tempArr.push(item.fileUrl);
      }
      for (const item of videoVoList) {
        videoArr.push(item.fileUrl);
      }
      this.imgList = tempArr;
      this.videoList = videoArr;
    },
  },
  data() {
    return {
      imgList: [],
      videoList: [],
      controls: true,
    };
  },
};
</script>

<style scoped>
.img-wrap{
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 20px;
}
</style>
